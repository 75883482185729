<template>
    <OIframe class="h-100 w-100" :src="url" ref="viewerRef" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { OIframe } from 'o365-ui-components';
import { GlobalMessenger } from 'o365-modules';
import { userSession, API } from 'o365-modules';

export interface Props {
    fileName?: string,
    primKey?: string,
    viewName?: string,
    id?: number,
    annotWhereClause?: string,
    readOnly?: boolean,
    viewerOnly?: boolean
    returnBtnText?:string
    returnBtnURL?:string
    openBlob?:string
};

export interface FoxitAnnot {
    id: number,
    info: FoxitAnnotInfo,
}

export interface FoxitAnnotInfo {
    objectNumber: number,
    id: number,
    subject: string,
    text: string,
    title: string,
    type: string,
    name: string
}

const viewerRef = ref(null);
var gDebounce;
var gInitialAnnotsImport = false;
var gAnnots;

const encodeObject = (pValue: any) => {
    const jsonString = JSON.stringify({
        fileName: props.fileName,
        primKey: props.primKey,
        viewName: props.viewName,
        id: props.id,
        readOnly: props.readOnly,
        viewerOnly: props.viewerOnly,
        returnBtnText: props.returnBtnText,
        returnBtnURL: props.returnBtnURL,
        openBlob: props.openBlob
    });
    const encodedString = btoa(encodeURIComponent(jsonString));
    return encodedString;
}

const createEmptyDataItem = () => {
    return {
        Page: null,
        Annot: null,
        AnnotID: null,
        Type: null,
        Document_ID: null,
        Comment: null,
        Exclude: null
    }
}

const props = defineProps<Props>();
const url = computed(() => { return `/nt/foxit9-viewer?params=${encodeObject(props)}` })

const emit = defineEmits<{
    (e: 'annotationAdded', pData: FoxitAnnot[], pPdfViewer: any),
    (e: 'annotationUpdated', pData: FoxitAnnot[], pPdfViewer: any),
    (e: 'annotationRemoved', pData: FoxitAnnot[], pPdfViewer: any),
}>();

GlobalMessenger.on(async (pPayload) => {
    if (pPayload === "PDFUI_CREATED") {
        const pdfui = viewerRef.value?.iframeRef.contentWindow.pdfui;
        if(typeof pdfui === 'undefined')
            return;
            
        const PDFViewCtrl = viewerRef.value?.iframeRef.contentWindow.PDFViewCtrl;
        const PDFViewer = await pdfui.getPDFViewer();
        const PDFCurrentDoc =  await pdfui.getCurrentPDFDoc();
        //const AnnotAuthMgr = PDFViewer.getAnnotAuthorityManager();
        const userName = await userSession.name;
        pdfui.setUserName(userName);

        if (!props.readOnly) {
            await insertAnnotationsToPDF(PDFCurrentDoc, await loadAnnotations(props.id))
            /* EVENTS */
            pdfui.eventEmitter.on(PDFViewCtrl.constants.ViewerEvents.annotationAdd, (data) => {
                PDFCurrentDoc.exportAnnotsToJSON(data).then((annotations) =>{
                    createAnnotation(props.id,annotations).then(() => {
                        loadAnnotations(props.id).then((annots) => {
                            insertAnnotationsToPDF(PDFCurrentDoc,annots)
                        })
                    })
                })
            })

            pdfui.eventEmitter.on(PDFViewCtrl.constants.ViewerEvents.annotationUpdated, (data) => {
                if(gDebounce !== null)
                    clearTimeout(gDebounce)
                
                gDebounce = setTimeout(function () {
                    PDFCurrentDoc.exportAnnotsToJSON(data).then((annotations) =>{
                        updateAnnotation(annotations);
                    })
                }, 500)
            })

            pdfui.eventEmitter.on(PDFViewCtrl.constants.ViewerEvents.annotationRemoved, (data) => {
                data.map(function(ele){
                    deleteAnnotation(ele.exportToJSON().name)
                })
            })

            pdfui.eventEmitter.on(PDFViewCtrl.constants.ViewerEvents.annotationImported, (doc) => {
                if(!gInitialAnnotsImport){
                    gInitialAnnotsImport = true;
                    return;
                }
                doc.exportAnnotsToJSON().then((annotations) => {
                    createImportedAnnotations(annotations, doc);
                })
            })
        }
    }
})

function loadAnnotations(id){
    return new Promise(function(resolve, reject) {
        resolve(retrieveAnnotations(id));
    })
}


function insertAnnotationsToPDF(pdfDoc,data){
    gAnnots = data;
    return new Promise(function(resolve, reject) {
        resolve(
            async function(){
                let annots = data.map(row => JSON.parse(row["Annot"])[0])
                pdfDoc.importAnnotsFromJSON(annots)
            }()
        );
    })
}


function removeWhiteSpaces(pText){
    return pText.split(/\r?\n/).map(x => x.trim()).join('\n');
}

function createImportedAnnotations(annots, PDFCurrentDoc){
    let vNewAnnots = ''; //imported annots
    let vNonExistantAnnots = '' //annotations that exist in pdf but not in DB
    let vAnnotsJsons = [];

    annots.map((annot) => {
        if(annot.name && annot.name !== undefined && annot.type !== 'popup' && !gAnnots?.find(row => row.AnnotID == annot.name)) {
            vNewAnnots += annot.name + ',';
            vAnnotsJsons.push(annot);
        }
    })

    if(vNewAnnots !== ''){
        annotExists(props.id,vNewAnnots.slice(0, -1)).then(async (data) => {
            vNonExistantAnnots += data.length > 0 ? data : "";
            vAnnotsJsons = vAnnotsJsons.filter((annot) => { return vNonExistantAnnots.includes(annot.name); })

            const asyncOperations = vAnnotsJsons.map(async(annot) => {
                await createAnnotation(props.id,[annot])
            })

            await Promise.all(asyncOperations)
        })
        .finally(() => {
            loadAnnotations(props.id).then((annots) => {
                insertAnnotationsToPDF(PDFCurrentDoc,annots)
            })
        })
    }
}

async function createAnnotation(id:number,annot:any){
    return API.requestPost(`/api/data`,
                            JSON.stringify({
                                operation:"create",
                                viewName:"atbv_Arena_DocumentsAnnotations",
                                values:
                                    {
                                        Annot:JSON.stringify(annot),
                                        Page:annot[0]["page"],
                                        AnnotID:annot[0]["name"],
                                        Document_ID:id,
                                        Comment:annot[0]["contents"]
                                    },
                                fields:[{name:"ID"},{name:"Page"},{name:"Annot"},{name:"AnnotID"},{name:"Type"},{name:"Exclude"}]
                            }));
}


async function retrieveAnnotations(document_ID:number){
    return API.requestPost(`/api/data`,
                            JSON.stringify({
                                operation:"retrieve",
                                viewName:"aviw_Arena_DocumentsAnnotations",
                                whereClause:"Document_ID = '"+document_ID+"'" + (props.annotWhereClause ? "AND " + props.annotWhereClause : ""),
                                fields:[{name:"ID"},{name:"Page"},{name:"Annot"},{name:"AnnotID"},{name:"Type"},{name:"Exclude"},{name:"CreatedBy"},{name:"UpdatedBy"}]
                            }))
}

async function updateAnnotation(annot:any){
    getAnnotationPrimKey(annot[0]["name"]).then((annotInfo) => {
        return API.requestPost(`/api/data`,
                                JSON.stringify({
                                    operation:"update",
                                    viewName:"atbv_Arena_DocumentsAnnotations",
                                    values:
                                        {
                                            Annot:JSON.stringify(annot),
                                            Page:annot[0]["page"],
                                            PrimKey:annotInfo[0]["PrimKey"],
                                            Comment:annot[0]["contents"]
                                        },
                                    fields:[{name:"ID"},{name:"Page"},{name:"Annot"},{name:"AnnotID"},{name:"Type"},{name:"Exclude"}]
                                }));
    })
}

async function deleteAnnotation(annotID:string){
    getAnnotationPrimKey(annotID).then((annotInfo) => {
        if(annotInfo.length == 0)
            return null;
        else
            return API.requestPost(`/api/data`,
                                    JSON.stringify({
                                        operation:"destroy",
                                        viewName:"atbv_Arena_DocumentsAnnotations",
                                        values:
                                            {
                                                PrimKey:annotInfo[0]["PrimKey"]
                                            },
                                        UniqueTable: 'atbl_Arena_DocumentsAnnotations'

                                    }));
        })
    }

async function getAnnotationPrimKey(annotID:string){
    return API.requestPost(`/api/data`,
                            JSON.stringify({
                                operation:"retrieve",
                                viewName:"aviw_Arena_DocumentsAnnotations",
                                whereClause:"AnnotID = '" + annotID + "' AND Document_ID = " + props.id,
                                fields:[{name:"PrimKey"},{name:"CreatedBy"},{name:"UpdatedBy"}]
                            }))
}

async function annotExists(id:number,annotIDs:string){
    return fetch('/api/arena/reviewHandler/checkAnnots/'+id+'/'+annotIDs)
        .then(response => response.json())
}

async function exportFdf(annots){
    const pdfui = viewerRef.value?.iframeRef.contentWindow.pdfui
    const PDFCurrentDoc =  await pdfui.getCurrentPDFDoc();
    return PDFCurrentDoc.exportAnnotsToFDF(0,annots);
}

async function exportAnnotations(){
    const pdfui = viewerRef.value?.iframeRef.contentWindow.pdfui
    const PDFCurrentDoc =  await pdfui.getCurrentPDFDoc();
    return PDFCurrentDoc.getAnnots();
}

defineExpose({
    viewerRef,
    exportFdf,
    exportAnnotations
})
</script>